<template>
  <referentiel-list
    :listTitle="'Liste des types de handicaps'"
    :referentielName="referentielName"
    :tableFields="tableFields"
    :emptyReferentielItem="emptyReferentielItem"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.libelle"
                @input="handleInput"
                type="text"
                class="form-control"
                id="libelle"
                name="libelle"
                placeholder="libelle"
              />
              <label for="libelle">Nom</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.code"
                @input="handleInput"
                type="text"
                class="form-control"
                id="code"
                name="code"
                placeholder="code"
              />
              <label for="code">Code</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import { mapGetters } from "vuex";
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";

export default {
  components: {
    ReferentielList,
    Multiselect,
  },
  computed: {
    ...mapGetters({}),
    referentielName() {
      return "typeHandicap";
    },
    emptyReferentielItem() {
      return {
        libelle: null,
        code: null,
      };
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "code", label: "Code" },
        { key: "libelle", label: "Libelle" },
        "actions",
      ];
    },
  },
  created() {},
  methods: {
    ...mapActions({}),
  },
};
</script>

<style></style>
